<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('MANAGEMENT.PAYMENT_FEES') }}
    </p>
    <section class="content-margin">
      <DRFilter @changeBusiness="changeBusiness"></DRFilter>

      <div class="d-flex flex-wrap">
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('MANAGE_PRODUCT.SELECT_PROVIDER') }}</label>
          <vue-select
            :options="suppliersList.options"
            class="input cus-select"
            v-model="suppliersList.selected"
            @input="changeSuppliersList"
          ></vue-select>
        </div>
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('MANAGE_PRODUCT.CHOOSE_PRODUCT') }}</label>
          <vue-select
            :options="suppliersProduct.options"
            class="input cus-select"
            v-model="suppliersProduct.selected"
            @input="changeSuppliersProduct"
          ></vue-select>
        </div>
      </div>

      <div class="d-flex m-t-20 flex-wrap">
        <div class="w-25 px15">
          <label>{{ $t('MANAGEMENT.PROFIT_COMMISSION_TO_MARKETER') }}</label>
          <input type="text" class="input" v-model="commissionProfit">
        </div>
        <div class="w-50 px15">
          <label></label>
          <button class="primary-btn fs16" @click="applyProfit" v-if="commissionProfit.length">
            {{ $t('MANAGEMENT.APPLY_PROFIT_COMMISSION_TO_PRODUCTS') }}
          </button>
          <button class="primary-btn fs16" v-else disabled>
            {{ $t('MANAGEMENT.APPLY_PROFIT_COMMISSION_TO_PRODUCTS') }}
          </button>
        </div>
      </div>
      <div class="d-flex m-t-20 flex-wrap">
        <div class="w-25 px15">
          <label>{{ $t('MANAGEMENT.EXTRA_CHARGE_TO_CONSUMER') }}</label>
          <input type="text" class="input" v-model="additionalPrice">
        </div>
        <div class="w-50 px15">
          <label></label>
          <button class="primary-btn fs16" @click="applyAdditionalPrice" v-if="additionalPrice.length">
            {{ $t('MANAGEMENT.APPLY_ADDITIONAL_PRICE_TO_CONSUMER') }}
          </button>
          <button class="primary-btn fs16" v-else disabled>
            {{ $t('MANAGEMENT.APPLY_ADDITIONAL_PRICE_TO_CONSUMER') }}
          </button>
        </div>
      </div>
    </section>

    <section class="table-content-part m-t-40">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('COMMON.SEARCH') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="tableData"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
      >
        <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ }">
          <div :key="h.value" class="d-flex align-items-center flex-column">
            <span>{{h.text}}</span>
            <v-checkbox 
              color="#0D3856" 
              class="cus-checkbox" 
              v-if="h.value=='distribution_fee'"
              v-model="a_distributionAll"
            ></v-checkbox>
            <v-checkbox 
              color="#0D3856" 
              class="cus-checkbox" 
              v-else-if="h.value=='is_authorized'"
              v-model="enabledAll"
            ></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.final_commission`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <input type="text" class="input" v-model="item.final_commission">
          </div>
        </template>
        <template v-slot:[`item.business_commission`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <input type="text" class="input" v-model="item.business_commission">
          </div>
        </template>
        <template v-slot:[`item.points`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <input type="text" class="input" v-model="item.points">
          </div>
        </template>
        <template v-slot:[`item.is_authorized`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.is_authorized"></v-checkbox>
          </div>
        </template>
      </v-data-table>
    </section>

    <div class="content-margin m-b-40">
      <button class="primary-btn btn-limit-width m-t-40" @click="updateTableData()">
        {{ $t("COMMON.SAVE") }}
      </button>
    </div>
    <ConfirmModal @confirmData="confirmData">
      <template v-slot:modal-content>
        This action will lead to changes in all products, including those that not shown after filtration! Are you sure you want to continue?
      </template>
    </ConfirmModal>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main> 
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DRFilter from "../../../components/management/DRFilter.vue"
import ConfirmModal from "../../../components/management/ConfirmModal.vue"

export default {
  name: 'DRPaymentProfit',
  components: {
    DRFilter,
    ConfirmModal
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: this.$t('REPORT_SALES.SUPPLIER_ID'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('DASHBOARD.PRODUCT_NAME'),
          align: 'center',
          sortable: false,
          value: 'product_name',
        },
        {
          text: this.$t('MANAGEMENT.ADDITIONAL_TO_CONSUMER'),
          align: 'center',
          sortable: false,
          value: 'final_commission',
        },
        {
          text: this.$t('MANAGEMENT.COMMISSIONERS_COMMISSION'),
          align: 'center',
          sortable: false,
          value: 'business_commission',
        },
        // {
        //   text: this.$t('REGISTER_RESELLER.ARTICLE', { num: '20' }),
        //   align: 'center',
        //   sortable: false,
        //   value: 'article',
        // },
        {
          text: this.$t('MANAGEMENT.POINTS'),
          align: 'center',
          sortable: false,
          value: 'points',
        },
        {
          text: this.$t('MANAGEMENT.ENABLED'),
          align: 'center',
          sortable: false,
          value: 'is_authorized',
        },
      ],
      tableData: [],
      suppliersList: {
        options: [],
        selected: ""
      },
      suppliersProduct: {
        options: [],
        selected: ""
      },
      commissionProfit: "",
      additionalPrice: "",
      confirmType: 1,
      businessID: "",
      distributeID: ""
    }
  },
  computed: {
    ...mapState({
      businessCommissionData: (state) => state.seller_tourism_percentage.businessCommissionData,
      suppliersData: (state) => state.seller_tourism_percentage.suppliersData,
      loading: (state) => state.seller_tourism_percentage.loading
    }),
    enabledAll: {
      get: function () {
        const checkedLength = this.tableData.filter(function(item) {
          return item.is_authorized==false;
        }).length;
        if (checkedLength) {
          return false
        } 
        return true;
      },
      set: function (value) {
        this.tableData.forEach(item => {
          item.is_authorized = value;
        })
      }
    }
  },
  methods: {
    ...mapActions('seller_tourism_percentage', {
      getBusinessCommission: 'getBusinessCommission',
      getSuppliers: 'getSuppliers',
      updateCommissionProfit: 'updateCommissionProfit',
      updateAdditionalPrice: 'updateAdditionalPrice',
      updateBusinessProduct: 'updateBusinessProduct',
    }),
    async changeBusiness(data) {
      this.businessID = data.business_id;
      this.distributeID = data.distribute_id;
      await this.getBusinessCommission({id: data.business_id});
      this.tableData = this.businessCommissionData;
      await this.getSuppliers({id: data.business_id});
      let suppliers_list = [];
      this.suppliersData.forEach(item => {
        suppliers_list.push({
          code: item.supplier_id,
          label: item.supplier_name
        })
      });
      this.suppliersList.options = suppliers_list;
      this.suppliersList.selected = "";
    },
    changeSuppliersList() {
      const supplier_id = this.suppliersList.selected.code;
      let productsBySupplier = this.businessCommissionData.filter((el) => {
        return el.supplier_id == supplier_id 
      });
      this.tableData = productsBySupplier;

      let productList = [];
      productsBySupplier.forEach((item) => {
        productList.push({
          code: item.product_id,
          label: item.product_name
        })
      });
      this.suppliersProduct.options = productList;
      this.suppliersProduct.selected = "";
      this.filteredTableData = this.tableData;
    },
    changeSuppliersProduct() {
      const product_id = this.suppliersProduct.selected.code;
      const productData = this.businessCommissionData.filter((el) => {
        return el.product_id == product_id;
      });
      this.tableData = productData;
      this.filteredTableData = this.tableData;
    },
    applyProfit() {
      this.confirmType = 1;
      this.$modal.show('confirmModal');
    },
    applyAdditionalPrice() {
      this.confirmType = 2;
      this.$modal.show('confirmModal');
    },
    async confirmData() {
      const business_id = this.businessID;
      const business_commission = this.commissionProfit
      let product_id = [];
      this.tableData.forEach((el) => {
        product_id.push(el.product_id);
      })
      if (this.confirmType == 1) {
        const params = {
          business_id: business_id,
          business_commission: business_commission,
          product_id: product_id
        }
        await this.updateCommissionProfit({params: params});
        this.commissionProfit = "";
      } else if (this.confirmType == 2) {
        const params = {
          business_id: business_id,
          final_commission: this.additionalPrice,
          product_id: product_id
        }
        await this.updateAdditionalPrice({params: params});
        this.additionalPrice = "";
      } 
      this.$modal.hide('confirmModal');
      this.changeBusiness({
        business_id: this.businessID,
        distribute_id: this.distributeID
      });
    },
    async updateTableData() {
      const business_id = this.businessID;
      const params = {
        business_id: business_id,
        products_list: this.businessCommissionData,
      };
      await this.updateBusinessProduct(params);
      this.changeBusiness({
        business_id: this.businessID,
        distribute_id: this.distributeID
      });
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  display: block;
  height: 27px;
}

@media screen and (max-width: 767px) {
  .form-item {
    width: 100%;
  }
}

@media screen and (max-width: 635px) {
  .filter-block {
    flex-wrap: wrap;
    button {
      margin-top: 10px;
    }
  }
  .primary-btn {
    height: unset;
    min-height: 44px;
  }
  .w-25,
  .w-50,
  .w-75 {
    width: 100% !important;
  }
}
</style>